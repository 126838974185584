import React from "react";

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function Countdown(params) {
  const {years, months, days, hours, minutes, seconds} = params.duration;

  let yearContent = '';

  if (years) {
    yearContent = <React.Fragment>
      <div>
        <div className="uk-countdown-number uk-countdown-days">{years}</div>
        <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Years</div>
        <div className="uk-countdown-label uk-margin-small uk-text-center uk-hidden@s">Y</div>
      </div>
      <div className="uk-countdown-separator">:</div>
    </React.Fragment>
  }

  return <div className="uk-grid-small uk-child-width-auto uk-grid ema-grid-center uk-margin-medium">
    {yearContent}
    <div>
      <div className="uk-countdown-number">{pad(months, 2)}</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Months</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-hidden@s">Mos</div>
    </div>
    <div className="uk-countdown-separator">:</div>
    <div>
      <div className="uk-countdown-number uk-countdown-days">{pad(days, 2)}</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Days</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-hidden@s">D</div>
    </div>
    <div className="uk-countdown-separator">:</div>
    <div>
      <div className="uk-countdown-number uk-countdown-hours">{pad(hours, 2)}</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Hours</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-hidden@s">H</div>
    </div>
    <div className="uk-countdown-separator">:</div>
    <div>
      <div className="uk-countdown-number uk-countdown-minutes">{pad(minutes, 2)}</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Minutes</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-hidden@s">M</div>
    </div>
    <div className="uk-countdown-separator">:</div>
    <div>
      <div className="uk-countdown-number uk-countdown-seconds">{pad(seconds, 2)}</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-visible@s">Seconds</div>
      <div className="uk-countdown-label uk-margin-small uk-text-center uk-hidden@s">S</div>
    </div>
  </div>;
}